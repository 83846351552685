import React, { FC } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Info } from "lucide-react";
import { useThemeStore } from "@/store";
import { Tooltip } from "react-tooltip";
import WesbsiteVisit from "@/assets/websiteVisit.svg";
import Download from "@/assets/download.svg";
import Traffic from "@/assets/Traffic.svg";
import Engagement from "@/assets/engagment.svg";
import WebsiteVisitDarkLogo from "@/assets/websiteVisitDarkLogo.svg";
import DownloadDarkLogo from "@/assets/downloadDarkLogo.svg";
import TrafficDarkLogo from "@/assets/trafficDarkLogo.svg";
import EngagementDarkLogo from "@/assets/engagmentDarkLogo.svg";
import "./index.css"

interface LeadCardProps {
  imgSrc: string;
  altText: string;
  title: string;
  onSelect: (title: string) => void;
  isSelected: boolean;
  tooltipContent: string;
}

const LeadCard: FC<LeadCardProps> = ({
  imgSrc,
  altText,
  title,
  onSelect,
  isSelected,
  tooltipContent,
}) => (
  <Card
    className={`relative border border-gray-200 p-4 h-36 w-64 bg-white dark:bg-[#2C2C2C] cursor-pointer ${isSelected ? "bg-Primary dark:bg-Primary" : "border-gray-200"
      }`}
    onClick={() => onSelect(title)}
  >
    <CardContent className="flex flex-col items-center">
      <div className="relative group">
        <Info
          data-tooltip-id={`tooltip-${title}`}
          className={` absolute top-[-10px] left-[100px]  ${isSelected ? "text-black" : ""
            }`}
          size={20}
        />
        <Tooltip
          id={`tooltip-${title}`}
          place="bottom"
        >
          {tooltipContent}
        </Tooltip>
      </div>
      <img className="h-16 w-16 " src={imgSrc} alt={altText} />
      <p
        className={`font-Inter font-semibold text-base pt-2 ${isSelected ? "text-black" : ""
          }`}
      >
        {title}
      </p>
    </CardContent>
  </Card>
);

interface SimpleLeadCardProps {
  onCardSelect: (title: string) => void;
  selectedCardTitle: string | null;
}

const SimpleLeadCard: FC<SimpleLeadCardProps> = ({
  onCardSelect,
  selectedCardTitle,
}) => {
  const { theme } = useThemeStore();

  const getImgSrc = (
    isSelected: boolean,
    darkLogo: string,
    normalLogo: string
  ) => {
    if (theme === "dark" && !isSelected) {
      return darkLogo;
    }
    return normalLogo;
  };

  const cardData = [
    {
      id: 1,
      imgSrc: getImgSrc(
        selectedCardTitle === "Website visit",
        WebsiteVisitDarkLogo,
        WesbsiteVisit
      ),
      altText: "Website visit",
      title: "Website visit",
      tooltipContent: "See how many people visit your website.",
    },
    {
      id: 2,
      imgSrc: getImgSrc(
        selectedCardTitle === "App downloads",
        DownloadDarkLogo,
        Download
      ),
      altText: "App downloads",
      title: "App downloads",
      tooltipContent: "Track app downloads over time.",
    },
    {
      id: 3,
      imgSrc: getImgSrc(
        selectedCardTitle === "Traffic",
        TrafficDarkLogo,
        Traffic
      ),
      altText: "Traffic",
      title: "Traffic",
      tooltipContent: "Monitor your website traffic.",
    },
    {
      id: 4,
      imgSrc: getImgSrc(
        selectedCardTitle === "Engagement",
        EngagementDarkLogo,
        Engagement
      ),
      altText: "Engagement",
      title: "Engagement",
      tooltipContent: "Analyze user engagement metrics.",
    },
  ];

  return (
    <div className="flex flex-col lg:flex-row gap-4">
      {cardData.map((card) => (
        <LeadCard
          key={card.id}
          imgSrc={card.imgSrc}
          altText={card.altText}
          title={card.title}
          onSelect={onCardSelect}
          isSelected={card.title === selectedCardTitle}
          tooltipContent={card.tooltipContent}
        />
      ))}
    </div>
  );
};

export default SimpleLeadCard;
