import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';

interface UserInterestResponse {
  userInterests: string[] | null;
  jamsWithInterests: string[] | null;
  language: {
    userPreferredLanguage: string[];
    userNativeLanguage: string[];
  };
}

interface FetchDataResponse {
  interests: string[];
  jamInterests: string[];
  language: {
    userPreferredLanguage: string[];
    userNativeLanguage: string[];
  };
}

// Custom hook to fetch interests and jamInterests
export const useFetchData = () => {
  return useQuery<FetchDataResponse, Error>({
    queryKey: ['interests', 'jamInterests'],
    queryFn: async () => {
      const apiUrl = import.meta.env.VITE_API_URL;    
      const { data } = await axios.get<UserInterestResponse[]>(apiUrl);
      const interestsSet = new Set<string>();
      const jamInterestsSet = new Set<string>();
      const languageSet = { userPreferredLanguage: new Set<string>(), userNativeLanguage: new Set<string>() };

      // Aggregate the interests from the users
      data.forEach(user => {
        // Add user interests if they exist
        if (user.userInterests && Array.isArray(user.userInterests)) {
          user.userInterests.forEach(interest => interestsSet.add(interest));
        }

        // Aggregate jam interests from jamsWithInterests
        if (user.jamsWithInterests && Array.isArray(user.jamsWithInterests)) {
          user.jamsWithInterests.forEach(interest => jamInterestsSet.add(interest));
        }

        // Add language details to the languageSet
        if (user.language) {
          user.language.userPreferredLanguage.forEach(language => languageSet.userPreferredLanguage.add(language));
          user.language.userNativeLanguage.forEach(language => languageSet.userNativeLanguage.add(language));
        }
      });

      // Return unique interests, jamInterests, and language information
      return {
        interests: Array.from(interestsSet),
        jamInterests: Array.from(jamInterestsSet),
        language: {
          userPreferredLanguage: Array.from(languageSet.userPreferredLanguage),
          userNativeLanguage: Array.from(languageSet.userNativeLanguage),
        },
      };
    },
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    cacheTime: 10 * 60 * 1000, // Inactive cache time for 10 minutes
    refetchOnWindowFocus: false, // Disable refetching on window focus
    retry: 2, // Retry failed requests up to 2 times
  } as UseQueryOptions<FetchDataResponse, Error>);
};
