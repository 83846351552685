import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Card, CardContent } from "@/components/ui/card";
import FileDropzone from "../fileAdzone";
import { Separator } from "@/components/ui/separator";
import CTAButton from "../ctaButton";
import TextArea from "../textAera";
import Preview from "../previewScreen";
import { FormData } from "@/components/types/internal/types/FormDataTypes";
import useStore from "@/zustand/store";

/**
 * Component to create and manage advertisement content.
 * Utilizes react-hook-form for form state management and Zustand for global state.
 * @returns JSX.Element
 */
const CreateAd = () => {
  // Extract methods and state from react-hook-form context
  const {
    control,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useFormContext<FormData>();

  // Access Zustand store actions for updating advertisement data
  const setAdImage = useStore((state) => state.setAdImage);
  const setBackgroundMedia = useStore((state) => state.setBackgroundMedia);
  const setCtaButtonTitle = useStore((state) => state.setCtaButtonTitle);

  // Watch form values to update Zustand store accordingly
  const adImage = watch("adImage");
  const backgroundMedia = watch("backgroundImage");
  const ctaButtonTitle = watch("ctaButtonTitle");
  const buttonUrl = watch("buttonUrl");
  const adText = watch("adText");
  const discountCode = watch("discountCode");
  const adName = watch("adName");
  const selectedLeadCardTitle = watch("selectedLeadCardTitle"); // Watch the goal
  const iosLink = watch("iosLink");
  const androidLink = watch("androidLink");

  /**
   * Synchronizes the ad image with Zustand store whenever it changes.
   */
  useEffect(() => {
    setAdImage(adImage);
  }, [adImage, setAdImage]);

  /**
   * Synchronizes the background media with Zustand store whenever it changes.
   */
  useEffect(() => {
    setBackgroundMedia(backgroundMedia);
  }, [backgroundMedia, setBackgroundMedia]);

  /**
   * Synchronizes the CTA button title with Zustand store whenever it changes.
   */
  useEffect(() => {
    setCtaButtonTitle(ctaButtonTitle);
  }, [ctaButtonTitle, setCtaButtonTitle]);

  return (
    <div className="bg-gray-50 flex flex-col md:flex-row lg:flex-row justify-center md:gap-2 md:items-start lg:items-start gap-10 lg:gap-20 px-4 py-14 lg:py-1 dark:bg-background overflow-hidden">
      <Card className="bg-gray-100 p-8 rounded-lg border border-gray-300 shadow-lg lg:w-1/2 dark:bg-background">
        <h3 className="text-xl lg:text-2xl font-semibold font-Inter pl-6 lg:pb-2">
          Create AD
        </h3>
        <CardContent>
          <Separator className="my-2 bg-gray-400" />
          <div className="mb-4">
            <div className="flex-row">
              <h4 className="text-base lg:text-xl font-semibold py-3 font-Inter">
                Front Side
              </h4>
              {/* AD name for the ad */}
              <CTAButton
                label="Ad Name"
                value={adName}
                onChange={(value) => {
                  // Trim spaces before setting the value
                  setValue("adName", value.trim(), { shouldValidate: true });
                }}
                placeholder="Enter Ad Name"
                register={register("adName", {
                  required: "Ad Name is required",
                  validate: (value) => value.trim() !== "" || "Ad Name cannot be empty or just spaces",
                })}
              />

              {/* Display validation error message for coupon code */}
              {errors.adName && (
                <p className="text-red-500 text-sm pb-2 px-2">
                  {errors.adName.message}
                </p>
              )}
              <p className="font-normal text-sm text-[#64748B] pb-2">
                For best output use image of 228x428
              </p>
            </div>
            {/* Dropzone for uploading advertisement image */}
            <FileDropzone
              label="Upload Ad Image"
              accept={{
                "image/jpeg": [".jpg", ".jpeg"], // Accept JPEG files
                "image/png": [".png"],           // Accept PNG files
                "video/mp4": [".mp4"],           // Accept MP4 videos
              }}
              maxFiles={1}
              control={control}
              name="adImage"
              required
            />
            <p className="font-normal text-sm text-[#64748B] pb-2">
              For best output use image of 228x428
            </p>
            {/* Dropzone for uploading background media (image or video) */}
            <FileDropzone
              label="Upload Background Media"
              accept={{
                "image/jpeg": [".jpg", ".jpeg"],  // Accept JPEG images
                "image/png": [".png"],            // Accept PNG images
                "video/mp4": [".mp4"],            // Accept MP4 videos
              }}
              maxFiles={1}
              control={control}
              name="backgroundImage"
              required
            />

          </div>
          <Separator className="my-2 bg-gray-400" />
          <div>
            <h4 className="text-base lg:text-xl font-semibold py-3 font-Inter">
              CTA/Button
            </h4>
            {/* CTA Button for setting the button title with validation */}
            <CTAButton
              label="Button Title"
              value={ctaButtonTitle}
              onChange={(title) => {
                // Trim spaces before setting the value
                const trimmedTitle = title.trim();
                setValue("ctaButtonTitle", trimmedTitle, { shouldValidate: true });
                setCtaButtonTitle(trimmedTitle); // Update Zustand store
              }}
              placeholder="Enter button title here"
              maxLength={15}
              register={register("ctaButtonTitle", {
                required: "Button title is required",
                validate: (title) => title.trim() !== "" || "Button title cannot be empty or just spaces",
                maxLength: {
                  value: 15,
                  message: "Button title must be less than 15 characters",
                },
              })}
            />

            {/* Display validation error message for button title */}
            {errors.ctaButtonTitle && (
              <p className="text-red-500 text-sm pb-2 px-2">
                {errors.ctaButtonTitle.message}
              </p>
            )}

            {selectedLeadCardTitle === "App downloads" && (
              <>
                {/* iOS App Link */}
                <CTAButton
                  label="IOS App Link"
                  value={iosLink}
                  onChange={(value) =>
                    setValue("iosLink", value, { shouldValidate: true })
                  }
                  placeholder="Enter iOS App Store URL"
                  register={register("iosLink", {
                    required: "IOS link is required",
                    pattern: {
                      value: /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/i,
                      message:
                        "Please enter a valid URL, starting with http:// or https://",
                    },
                  })}
                />
                {errors.iosLink && (
                  <p className="text-red-500 text-sm pb-2 px-2">
                    {errors.iosLink.message}
                  </p>
                )}

                {/* Android App Link */}
                <CTAButton
                  label="Android App Link"
                  value={androidLink}
                  onChange={(value) =>
                    setValue("androidLink", value, { shouldValidate: true })
                  }
                  placeholder="Enter Android Play Store URL"
                  register={register("androidLink", {
                    required: "Android link is required",
                    pattern: {
                      value: /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/i,
                      message:
                        "Please enter a valid URL, starting with http:// or https://",
                    },
                  })}
                />
                {errors.androidLink && (
                  <p className="text-red-500 text-sm pb-2 px-2">
                    {errors.androidLink.message}
                  </p>
                )}
              </>
            )}

            {selectedLeadCardTitle !== "App downloads" && (
              <CTAButton
                label="Button URL"
                value={watch("buttonUrl")}
                onChange={(value) =>
                  setValue("buttonUrl", value, { shouldValidate: true })
                }
                placeholder="Enter URL here"
                maxLength={200}
                register={register("buttonUrl", {
                  required: "Button URL is required",
                  pattern: {
                    value: /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/i,
                    message:
                      "Please enter a valid URL, starting with http:// or https://",
                  },
                })}
              />
            )}
            {/* Display validation error message for button URL */}
            {errors.buttonUrl && (
              <p className="text-red-500 text-sm pb-2 px-2">
                {errors.buttonUrl.message}
              </p>
            )}
          </div>
          <Separator className="my-2 bg-gray-400" />
          <div>
            <h2 className="text-base lg:text-xl font-semibold py-3 font-Inter">
              Back Side
            </h2>
            {/* TextArea for adding coupon text with validation */}
            <TextArea
              label="Add Text"
              value={adText}
              onChange={(value) => {
                // Trim spaces before setting the value
                setValue("adText", value.trim(), { shouldValidate: true });
              }}
              placeholder="Text here"
              maxLength={100}
              register={register("adText", {
                required: "Ad Text is required",
                maxLength: {
                  value: 100,
                  message: "Ad Text must be less than 100 characters",
                },
                validate: (value) => value.trim() !== "" || "Ad Text cannot be empty or just spaces",
              })}
            />

            {/* Display validation error message for coupon text */}
            {errors.adText && (
              <p className="text-red-500 text-sm pb-2 px-2">
                {errors.adText.message}
              </p>
            )}
            {/* CTA Button for adding discount codes with validation */}
            <CTAButton
              label="Add Discount Codes"
              value={discountCode}
              onChange={(value) =>
                setValue("discountCode", value, { shouldValidate: true })
              }
              placeholder="Enter code here"
              register={register("discountCode", {
                required: "Discount code is required",
                pattern: {
                  value: /^JAM(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]{5}ME$/,
                  message:
                    "Coupon code must start with 'JAM', contain exactly 5 alphanumeric characters (at least one letter and one number), and end with 'ME'.",
                },
              })}
              required={true} // Will display "(optional)"
            />

            {/* Display validation error message for coupon code */}
            {errors.discountCode && (
              <p className="text-red-500 text-sm pb-2 px-2">
                {errors.discountCode.message}
              </p>
            )}
          </div>
        </CardContent>
      </Card>
      {/* Preview component for displaying the advertisement */}
      <div className="">
        <Preview />
      </div>
    </div>
  );
};

export default CreateAd;
