import React from "react";
import { Header } from "../internal/header";
import { useLocation } from "react-router-dom";

export default function HomeLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const isHomeScreen = location.pathname === "/";
  // const isRegisterProfilePage = location.pathname === "/register/profile"; // Check if the page is /register.profile

  return (
    <div className="flex flex-col min-h-screen">
      {/* Only render the Header if it's not the /register.profile page */}
      {<Header isHomeScreen={isHomeScreen} />}
      <main className="flex-grow pt-20 bg-gray-50 dark:bg-background">
        {children}
      </main>
    </div>
  );
}
