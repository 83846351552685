import React, { useState, useEffect } from "react";
import { useFormContext, Controller, FieldValues, FieldError } from "react-hook-form";
import { Info, X } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import useStore from "@/zustand/store";
import { useFetchData } from "@/hooks/useFetchData"; // Import the useFetchData hook
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface LanguageFieldProps {
  value?: string[];
}

const LanguageField: React.FC<LanguageFieldProps> = ({ value }) => {
  const { control, setValue, formState: { errors }, trigger } = useFormContext<FieldValues>();
  const [languages, setLanguages] = useState<string[]>(value || []);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [duplicateError, setDuplicateError] = useState<string>("");
  const setLanguage = useStore((state) => state.setLanguage);

  const { data: fetchedData, isLoading, error } = useFetchData();

  useEffect(() => {
    if (value) {
      setLanguages(value);
    }
  }, [value]);

  // Combining userPreferredLanguage and userNativeLanguage from the fetched data and deduplicating
  const combinedLanguages = fetchedData
    ? Array.from(
      new Set([
        ...fetchedData.language.userPreferredLanguage,
        ...fetchedData.language.userNativeLanguage,
      ])
    )
    : [];

  const handleAddLanguage = (language: string) => {
    if (language && languages.length < 10) {
      if (languages.includes(language)) {
        setDuplicateError(`"${language}" is already added.`);
      } else {
        const newLanguages = [...languages, language];
        setLanguages(newLanguages);
        setValue("language", newLanguages);
        setLanguage(newLanguages);
        setSearchTerm("");
        trigger("language");
        setDuplicateError("");
      }
    }
  };

  const handleRemoveLanguage = (index: number) => {
    const newLanguages = languages.filter((_, i) => i !== index);
    setLanguages(newLanguages);
    setValue("language", newLanguages); // Ensure the correct field name here
    trigger("language");
  };

  // Filter the languages based on the search term
  const filteredLanguages = combinedLanguages
    ? combinedLanguages.filter((language) =>
      language.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  const languageError = errors.language as FieldError | undefined;
  const languageLimitReached = languages.length >= 10;

  return (
    <div className="flex flex-col mt-4 font-Inter">
      <Label htmlFor="languages" className="font-semibold text-sm lg:text-base flex mb-1 gap-1 ml-1">
        <Label> Languages </Label>
        <div className="-mt-0.5">
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger> <Info size={16} /> </TooltipTrigger>
              <TooltipContent side="right">
                Users selected these languages during signup. Target your ads to reach audiences who understand these languages for better engagement
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </Label>

      <Controller
        name="language"
        control={control}
        render={() => (
          <div className="relative">
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setDuplicateError("");
              }}
              placeholder={
                languageLimitReached
                  ? "You can't add more languages. Remove an existing one to update."
                  : "Type to search languages"
              }
              className={`border p-2 rounded-md w-full ${isLoading || languageLimitReached ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
              disabled={isLoading || !!error || languageLimitReached}
            />

            {/* Show suggestions or no-match message */}
            {searchTerm && !languageLimitReached && (
              <ul className="absolute z-10 w-full mt-2 bg-white dark:bg-background border rounded-md shadow-lg max-h-48 overflow-y-auto">
                {filteredLanguages.length > 0 ? (
                  filteredLanguages.map((language) => (
                    <li
                      key={language}
                      onClick={() => !languages.includes(language) && handleAddLanguage(language)}
                      className={`p-2 ${languages.includes(language)
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:bg-blue-500 hover:text-white cursor-pointer"
                        }`}
                    >
                      {language} {languages.includes(language) && <span className="text-xs">(Already added)</span>}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500 cursor-default">
                    No matching languages available
                  </li>
                )}
              </ul>
            )}
          </div>
        )}
      />

      {duplicateError && <p className="text-red-500 text-sm pb-2 px-2">{duplicateError}</p>}
      {languageError?.message && (
        <p className="text-red-500 text-sm pb-2 px-2">{languageError.message}</p>
      )}

      {languages.length > 0 && (
        <div className="mt-4">
          <h4 className="font-semibold text-sm lg:text-base">Added Languages:</h4>
          <ul className="list-none flex flex-wrap gap-2">
            {languages.map((language, index) => (
              <li key={index} className="flex items-center space-x-2 text-sm">
                <span className="bg-gray-200 text-gray-800 py-1 px-4 rounded-full flex items-center space-x-2">
                  <span>{language}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveLanguage(index)}
                    className="text-red-500 hover:text-red-700 p-1"
                  >
                    <X size={14} />
                  </button>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageField;
