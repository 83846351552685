import AppProvider from "@/providers";
import AppRouter from "@/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import CookieConsentComponent from "@/components/internal/cookieConsent/CookieConsentComponent";
import Chatbot from "@/components/internal/chatbot/bot";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default function App() {
  return (
    <AppProvider>
      <AppRouter />
      <ToastContainer />
      {/* <Chatbot /> */}
      <CookieConsentComponent />
    </AppProvider>
  );
}
